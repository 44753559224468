import React from "react"
import { graphql } from "gatsby"
import Header from "../components/header.js"
import parse from "html-react-parser"
import Seo from "../components/seo.js"
import { Helmet } from "react-helmet"

export default function Template({
  data, // this prop will be injected by the GraphQL query below.
}) {
  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const redirectString = "0; URL=" + frontmatter.extUrl
  return (
    <>
      <Seo
        title={"Marc Kreidler: " + frontmatter.title}
        description={parse(html)}
        ogTitle={"Marc Kreidler: " + frontmatter.title}
        ogDescription={parse(html)}
        twTitle={"Marc Kreidler: " + frontmatter.title}
        twDescription={parse(html)}
        featuredImage={frontmatter.featuredImage}
        ogImage={frontmatter.featuredImage}
        twImage={frontmatter.featuredImage}
      />
      {frontmatter.gatsby && (
        <Helmet>
          <meta http-equiv="refresh" content={redirectString} />
        </Helmet>
      )}
      <div className="single bg-offWhite">
        <div className="lg:flex">
          <Header />
          {frontmatter.tag === "Web" && (
            <div className="overflow-hidden entry-content w-full h-mobile-full sm:h-tablet-full lg:h-[100vh]">
              <iframe
                title={frontmatter.title}
                src={frontmatter.extUrl}
                style={{ width: "100%", height: "100%" }}
              ></iframe>
            </div>
          )}
          {frontmatter.tag === "Video" && frontmatter.player === "vimeo" && (
            <>
              <div class="overflow-hidden bg-black flex w-full h-mobile-full sm:h-tablet-full lg:hidden items-center">
                <iframe
                  title={frontmatter.title}
                  src={"https://player.vimeo.com/video/" + frontmatter.extUrl}
                  style={{ width: "100%", height: "54vw" }}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="overflow-hidden bg-black hidden w-full h-[100vh] lg:flex items-center">
                <iframe
                  title={frontmatter.title}
                  src={"https://player.vimeo.com/video/" + frontmatter.extUrl}
                  style={{ width: "100%", height: "100%" }}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </>
          )}
          {frontmatter.tag === "Video" && frontmatter.player === "youtube" && (
            <>
              <div class="overflow-hidden bg-black flex w-full h-mobile-full sm:h-tablet-full lg:hidden items-center">
                <iframe
                  title={frontmatter.title}
                  src={"https://www.youtube.com/embed/" + frontmatter.extUrl}
                  style={{ width: "100%", height: "54vw" }}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div class="overflow-hidden bg-black hidden w-full h-[100vh] lg:flex items-center">
                <iframe
                  title={frontmatter.title}
                  src={"https://www.youtube.com/embed/" + frontmatter.extUrl}
                  style={{
                    width: "100%",
                    height: "calc( (100vw - 162px) * .54 )",
                  }}
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
            </>
          )}
          {frontmatter.tag === "Informational" && (
            <div className="entry-content w-full pt-6 pb-16 lg:py-12">
              <div className="px-6 lg:px-12 max-w-6xl mx-auto">
                {parse(html)}
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export const pageQuery = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        slug
        tag
        title
        player
        extUrl
        gatsby
        featuredImage {
          childImageSharp {
            gatsbyImageData(placeholder: TRACED_SVG, formats: WEBP, width: 800)
          }
        }
      }
    }
  }
`
